import { Dialog } from "@mui/material";
import React from "react";
import { AppLinkSelector } from "../AppLinkSelector/AppLinkSelector";
import { AppNavItem } from "../WaffleMenu";

export type SubMenuDialogProps = {
  showSubMenuDialog: boolean;
  setShowSubMenuDialog: (show: boolean) => void;
  closeSubMenuDialog: () => void;
  appNavItem: AppNavItem;
  navigateToLaunchApp: (appNavItem: AppNavItem) => void;
};
export const SubMenuDialog = ({
  showSubMenuDialog,
  setShowSubMenuDialog,
  closeSubMenuDialog,
  appNavItem,
  navigateToLaunchApp,
}: SubMenuDialogProps) => {
  return (
    <Dialog
      open={showSubMenuDialog}
      onClose={closeSubMenuDialog}
      scroll={"paper"}
      PaperProps={{
        sx: { position: "absolute", right: 110, top: 50 },
      }}
    >
      <AppLinkSelector
        closeSubMenuDialog={closeSubMenuDialog}
        appNavItem={appNavItem}
        navigateToLaunchApp={navigateToLaunchApp}
      />
    </Dialog>
  );
};

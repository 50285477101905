import { QueryClientProviderProps } from "@tanstack/react-query";
import { createContext } from "react";

type fetchQueryArgs = {
  queryKey: [string, string];
  queryFn: () => {};
  staleTime: number;
};
export interface IQueryClient {
  fetchQuery(args: fetchQueryArgs): Promise<any>;
}
export type CustomQueryClientContextType = {
  client: IQueryClient;
};

export const CustomQueryClientContext = createContext<
  CustomQueryClientContextType | QueryClientProviderProps | null
>(null);

import React, { useState, useEffect } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { trackPromise } from "react-promise-tracker";
import {
  UserInfoJson,
  School,
  createEmptyUserInfo,
} from "./interfaces/UserInfoJson";
import i18n from "i18next";
import { Spinner } from "./components/Spinner";
import AppRouter from "./AppRouter";
import {
  Entitlement,
  getAppNavItemsByEntitlements,
} from "./components/Waffle/ConvertEntitlements";
import { Entitlements } from "./components/Waffle/Entitlements";
import { CustInfoData } from "./interfaces/CustInfo";
import { AuthCookieContext } from "./components/AuthCookieContext";
import { UserSettingsJson } from "./interfaces/UserSettingsJson";
import { EntitlementContext } from "./components/EntitlementContext";
import { AppNavItem } from "./components/Waffle/WaffleMenu";

function AppRouterWrapper() {
  const [userInfo, setUserInfo] = useState<UserInfoJson | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettingsJson | null>(
    null
  );
  const [hasLoadedUserInfo, setHasLoadedUserInfo] = useState<boolean>(false);
  const [hasLoadedCustInfo, setHasLoadedCustInfo] = useState<boolean>(false);
  const [custInfo, setCustInfo] = useState<CustInfoData | null>(null);
  const [schools, setSchools] = useState<School[] | null>(null);
  const [entitlements, setEntitlements] = useState<Entitlement[] | null>(null);
  const [appNavItems, setAppNavItems] = useState<AppNavItem[] | null>(null);
  const [LDProviderComponent, setLDProviderComponent] =
    React.useState<React.ReactNode>(null);

  const authCookieContext = React.useContext(AuthCookieContext);

  useEffect(() => {
    const abortController = new AbortController();
    if (authCookieContext?.role === "anonymous") {
      // initialize userinfo with data from the cookie
      // TODO: https://illuminate.atlassian.net/browse/SZSTART-1698 (cleanup this userinfo type)
      const anonymousUserInfo = {
        ...createEmptyUserInfo(),
        customer_name: authCookieContext.customer_name || "",
        organizationId: authCookieContext.customer_id || 0,
        rgp_tenant: authCookieContext.tenant_id || "",
        userId: authCookieContext.user_id || "",
        email: authCookieContext.email || "",
        countryCode: authCookieContext.countryCode || "US",
      };
      setUserInfo(anonymousUserInfo);
      setHasLoadedUserInfo(true);
    } else {
      trackPromise(
        fetch(`/userinfo/`, { signal: abortController.signal })
          .then((res) => {
            if (res.status === 401) {
              window.location.href =
                process.env.REACT_APP_DEFAULT_HOME_URI ||
                "https://schools.renaissance.com/";
              return null;
            } else {
              return res.json();
            }
          })
          .then(
            (res: UserInfoJson) => {
              setUserInfo(res);
              if (res.schools) {
                res.schools.sort((a, b) =>
                  a.officialSchoolName.localeCompare(b.officialSchoolName)
                );
                setSchools(res.schools);
              }
              if (res.countryCode === "GB") {
                i18n.changeLanguage("enUK");
              }
              setHasLoadedUserInfo(true);
            },
            (error) => {
              setHasLoadedUserInfo(true);
            }
          )
      );
    }

    return () => {
      abortController.abort();
    };
  }, [authCookieContext?.customer_id]);

  // Function to initialize Pendo
  const initializePendo = (res: UserInfoJson, appNavItems: AppNavItem[]) => {
    (window as any).pendo.initialize({
      excludeAllText: true,
      visitor: {
        id: authCookieContext?.rid,
        id_ra: res.userId,
        role_ra: res.role,
        isRenaissance: res.email && res.email.includes("@renaissance.com"),
        isSchoolzilla: res.email && res.email.includes("@schoolzilla.com"),
        has_freckle: !!appNavItems.find((item) => item.id === "freckle"),
      },
      account: {
        id: res.organizationId,
        name: res.customer_name,
        tier: res.sz_tier || "starter",
        tenant: res.rgp_tenant,
        countryCode: res.countryCode,
        isReactApp: true,
      },
    });
  };
  useEffect(() => {
    if (userInfo && appNavItems) {
      initializePendo(userInfo, appNavItems);
    }
  }, [userInfo, appNavItems]);

  useEffect(() => {
    const abortController = new AbortController();
    trackPromise(
      fetch(`/appnav`, {
        signal: abortController.signal,
      })
        .then((res) => res.json())
        .then(
          (res: { appnav: Entitlement[] | null }) => {
            let entitlements;
            if (res) {
              entitlements = res.appnav;
            } else {
              // set a default
              entitlements = Entitlements.appnav;
            }
            setEntitlements(entitlements);
            const appNavItems = getAppNavItemsByEntitlements(entitlements);
            setAppNavItems(appNavItems);
          },
          (error) => {
            // set a default
            setEntitlements(Entitlements.appnav);
            const appNavItems = getAppNavItemsByEntitlements(
              Entitlements.appnav
            );
            setAppNavItems(appNavItems);
          }
        )
    );
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    trackPromise(
      fetch(`/usersettings`, {
        signal: abortController.signal,
      })
        .then((res) => res.json())

        .then(
          (res: UserSettingsJson) => {
            setUserSettings(res);
          },
          (error) => {
            console.error("Error fetching usersettings", error);
          }
        )
    );
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    trackPromise(
      fetch(`/custinfo/`, {
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.status === 401) {
            window.location.href =
              process.env.REACT_APP_DEFAULT_HOME_URI ||
              "https://schools.renaissance.com/";
            return null;
          } else {
            return res.json();
          }
        })
        .then(
          (res: CustInfoData) => {
            setCustInfo(res);
            setHasLoadedCustInfo(true);
          },
          (error) => {
            console.error("Error fetching custinfo", error);
            setHasLoadedCustInfo(true);
          }
        )
    );
    return () => {
      abortController.abort();
    };
  }, []);

  React.useEffect(() => {
    const loadLDProvider = async () => {
      if (
        !userInfo ||
        !custInfo ||
        !entitlements ||
        !appNavItems ||
        !authCookieContext
      ) {
        return;
      }

      const user = {
        key: userInfo.userId.toString(),
        kind: "user",
        organizationId: userInfo.organizationId,
        licenseLevel: custInfo.license_level,
        role: userInfo.role,
        countryCode: userInfo.countryCode,
        isPortfolioOrg: custInfo.portfolio_organization,
      };
      const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || "",
        context: user,
      });
      setLDProviderComponent(
        <LDProvider>
          <EntitlementContext.Provider value={{ entitlements, appNavItems }}>
            <AppRouter
              userInfo={userInfo}
              userSettings={userSettings}
              schools={schools}
              isEmptyCustomer={
                (hasLoadedUserInfo && userInfo === null) ||
                (hasLoadedCustInfo && custInfo === null) ||
                (hasLoadedUserInfo &&
                  (schools === null || schools.length === 0)) ||
                (hasLoadedUserInfo && userInfo.role === "anonymous")
              }
            />
          </EntitlementContext.Provider>
        </LDProvider>
      );
    };
    loadLDProvider();
  }, [
    userInfo,
    userSettings,
    custInfo,
    schools,
    entitlements,
    appNavItems,
    authCookieContext,
    hasLoadedUserInfo,
    hasLoadedCustInfo,
  ]);

  if (!hasLoadedUserInfo || !hasLoadedCustInfo) {
    // we don't have to check if schools has loaded yet because it's set at the same time as userinfo
    return <Spinner />;
  }

  return <React.Fragment>{LDProviderComponent}</React.Fragment>;
}

export default AppRouterWrapper;

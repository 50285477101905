import React from "react";
import ExploreLayout2 from "./ExploreLayout2";
import { styled } from "@mui/system";
import { ReactComponent as RR } from "../../assets/images/books_7-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_7-SP.svg";
import { ReactComponent as FF } from "../../assets/images/books_7-FF.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade7EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Authentic Spanish";
  const title3 = "Futuristic Finds";

  return (
    <ExploreLayout2
      image1={RR}
      image2={SP}
      image3={FF}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>As Brave as You</strong> • Jason Reynolds • 4.8, MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Ancestor Approved: Intertribal Stories for Kids</strong> •
          Cynthia Leitich Smith • 5.0, MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Mi propio cuartito</strong> • Amada Irma Pérez • 3.9, MG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Bailando con un fantasma</strong> • Juan Sauvageau • 3.5, MG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Set the stage for inspiration and innovation, opening kids’ minds to
          question “what if” and imagine all that is possible.
        </Paragraph>
        <AdditionalParagraph>
          <strong>The Wild Robot</strong> • Peter Brown • 5.1, MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>My Life as a Coder</strong> • Janet Tashjian • 5.4, MG
        </AdditionalParagraph>
      </div>
    </ExploreLayout2>
  );
};

export default Grade7EnUS;

import * as React from "react";
import { t } from "i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { NavIconContainer, NavIcon } from "../lib/navicon";
import { ReactComponent as Help } from "../../assets/images/question.svg";
import PendoTrack from "../Pendo/Pendo";
import { useImportScript } from "../Utils";
import { useContext, useEffect, useState } from "react";
import { AuthCookieContext } from "../AuthCookieContext";
import { openLiveChat } from "../Utils";

const HelpMenuItem = ({
  title,
  href,
  displayText,
  onClick,
  ariaLabel,
  tabIndex,
}: {
  title: string;
  href: string;
  displayText: string;
  onClick: () => void;
  ariaLabel: string;
  tabIndex?: number;
}) => (
  <MenuItem
    title={title}
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
    aria-label={ariaLabel}
    tabIndex={tabIndex}
    sx={{
      "&:focus": {
        textDecoration: "underline",
      },
    }}
  >
    <Typography textAlign="center">{displayText}</Typography>
  </MenuItem>
);

export function NavbarHelp({
  rgpTenantId,
  tabIndex,
}: {
  rgpTenantId: string | undefined;
  tabIndex?: number;
}) {
  useImportScript(process.env.REACT_APP_LIVE_CHAT || "");
  const menuRef = React.useRef<HTMLDivElement>(null);
  const helpUrl = t("HELP_URL");
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [customerCenterUrl, setCustomerCenterUrl] = useState<
    string | undefined
  >();
  const authCookieContext = useContext(AuthCookieContext);

  useEffect(() => {
    if (authCookieContext?.customercenterurl)
      setCustomerCenterUrl(authCookieContext.customercenterurl);
  }, []);

  const handleOpenHelpMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
    setTimeout(() => {
      const ulElement = menuRef.current?.querySelector("ul");
      const firstAnchor = ulElement?.querySelector("a");
      firstAnchor?.focus();
    }, 0);
  };

  const handleCloseHelpMenu = () => {
    setAnchorElUser(null);
  };

  const selectMenuItem = (type: string, url: string) => {
    setAnchorElUser(null);
    switch (type) {
      case "help":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Help Link",
          },
        });
        window.open(url, "_blank");
        break;
      case "chat-support":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Chat With Support",
          },
        });
        openLiveChat(rgpTenantId);
        break;
      case "customer-center":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Customer Center",
          },
        });
        window.open(url, "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <NavIconContainer
        data-testid="help-icon"
        title="Help"
        onClick={handleOpenHelpMenu}
        aria-haspopup="true"
        aria-controls="help-menu"
        aria-expanded={Boolean(anchorElUser)}
        aria-label="Help"
      >
        <NavIcon>
          <Help />
        </NavIcon>
      </NavIconContainer>
      <Menu
        sx={{ mt: "45px" }}
        id="help-menu"
        data-testid="help-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseHelpMenu}
        ref={menuRef}
      >
        <HelpMenuItem
          title="Help Documentation"
          href={helpUrl}
          displayText="Help"
          onClick={() => selectMenuItem("help", helpUrl)}
          ariaLabel="Help Documentation"
          tabIndex={0}
        />
        {rgpTenantId && (
          <HelpMenuItem
            title="Live Chat"
            href=""
            displayText="Chat with Support"
            onClick={() => selectMenuItem("chat-support", "")}
            ariaLabel="Live Chat"
            tabIndex={0}
          />
        )}
        {customerCenterUrl && (
          <HelpMenuItem
            title="Customer Center"
            href={customerCenterUrl}
            displayText="Customer Center"
            onClick={() => selectMenuItem("customer-center", customerCenterUrl)}
            ariaLabel="Customer Center"
            tabIndex={0}
          />
        )}
      </Menu>
    </>
  );
}

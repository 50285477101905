import { createContext } from "react";
import { UserInfoJson } from "../interfaces/UserInfoJson";
import { UserSettingsJson } from "../interfaces/UserSettingsJson";

type UserContextType = {
  userInfo: UserInfoJson | null;
  userSettings?: UserSettingsJson | null;
};

export const UserContext = createContext<UserContextType | null>(null);

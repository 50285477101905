import { createContext } from "react";
import { Entitlement } from "./Waffle/ConvertEntitlements";
import { AppNavItem } from "./Waffle/WaffleMenu";

type EntitlementContextType = {
  entitlements: Entitlement[] | null;
  appNavItems: AppNavItem[] | null;
};

export const EntitlementContext = createContext<EntitlementContextType | null>(
  null
);

import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useFlags } from "launchdarkly-react-client-sdk";
import WaffleIcon from "./WaffleIcon";
import { EntitlementContext } from "../EntitlementContext";
import RenaissanceNextPane from "../RenaissanceNextPane/RenaissanceNextPane";
/* Styles from https://git.renaissance.com/RenaissancePlace/element-RenaissanceHeader/blob/main/projects/renaissance-header/src/app/waffle-menu/waffle-menu.component.scss */
const WaffleMenuContainer = styled("div")`
  a {
    color: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-decoration: inherit;
  }

  .ren-waffle__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .glyph {
      color: #737373;
    }
  }

  .app-nav-menu {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: var(--std-padding-xxs);
    margin: var(--std-margin-xxs);

    .app-nav-grid {
      list-style: none;
      display: flex;
      flex-direction: column;

      .app-nav-grid-row {
        display: flex;
        flex-direction: row;

        .app-tile {
          width: 80px;
          height: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 5px;
          border-radius: 8px;
          background-color: var(--lightgrey);
          font-size: var(--std-font-xs);

          .app-image {
            width: 30px;
            height: 30px;
            padding-top: 2px;
            padding-bottom: 3px;

            &:hover {
              width: 35 px;
              height: 35 px;
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }

          .renu-text {
            font-size: 11px;
          }

          &:hover {
            outline: 2px solid var(--red);
            background-color: var(--white);
            cursor: pointer;

            .app-image {
              transition: 0.2s;
              width: 35px;
              height: 35px;
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }
`;

const AppLinkSelector = styled("div")`
  position: relative;
  right: rem(80);
  background: var(--white);
`;

export interface AppNavConfig {
  apps: AppNav[];
  availableApps: AppNavItem[];
  availableData: AppNavItem[];
  launchBaseUri: string;
}

export interface AppNav {
  applicationId: string;
  taskId: string;
}

export interface AppNavItem {
  id?: string;
  displayName: string;
  longDisplayName?: string;
  subHeader?: string;
  applicationId?: string;
  taskId?: string;
  children?: AppNavItem[];
  url?: string;
  tileLinkType?: TileLinkType;
  imagePrefix?: string;
  deepLinkId?: string;
}

export enum AppNavItemIds {
  REPORTS = "REPORTS",
  AR = "AR",
  STAR = "STAR",
  RENU = "RENU",
}

export enum TileLinkType {
  FRECKLE_REPORTS_PAGE = 1,
  LALILO_REPORTS_PAGE = 2,
  MYON_REPORTS_PAGE = 3,
  STARPHONICS_REPORTS_PAGE = 4,
}

export interface WaffleGrid {
  gridRows: WaffleGridRow[];
}

export interface WaffleGridRow {
  tiles: AppNavItem[];
}

const parseLinksIntoGrid = (
  availableApps: AppNavItem[],
  availableData: AppNavItem[] | null
): WaffleGrid => {
  let grid: WaffleGrid = {
    gridRows: [],
  };
  let currentRow: WaffleGridRow = { tiles: [] };
  // do data first to match order in desktop left nav
  if (availableData) {
    for (const tile of availableData) {
      if (currentRow.tiles.length >= 3) {
        grid.gridRows.push(currentRow);
        currentRow = { tiles: [] };
      }
      currentRow.tiles.push(tile);
    }
  }
  for (const tile of availableApps) {
    if (currentRow.tiles.length >= 3) {
      grid.gridRows.push(currentRow);
      currentRow = { tiles: [] };
    }
    currentRow.tiles.push(tile);
  }
  grid.gridRows.push(currentRow);
  return grid;
};

const getExpandDisabledForTopLevelApp = (appNavItem: AppNavItem): boolean => {
  if (appNavItem.id === AppNavItemIds.AR) {
    return true;
  }
  return false;
};

const WaffleMenu = ({
  availableApps,
  availableData,
  openNavDialog,
  navigateToLaunchApp,
}: {
  availableApps: AppNavItem[];
  availableData: AppNavItem[] | null;
  openNavDialog: (item: AppNavItem) => void;
  navigateToLaunchApp: (appNavItem: AppNavItem) => void;
}) => {
  const { frontendRenNextPane } = useFlags();
  const [waffleGrid, setWaffleGrid] = useState<WaffleGrid>({ gridRows: [] });
  const [hasTeacherJourneyLaunch, setHasTeacherJourneyLaunch] = useState(false);
  const entitlementContext = React.useContext(EntitlementContext);
  React.useEffect(() => {
    const hasAccessToTeacherJourneyLaunch =
      entitlementContext?.entitlements?.some(
        (entitlement) =>
          entitlement.applicationId === "0" &&
          entitlement.taskId === "NEXT_TEACHER_AS_ADMIN"
      );
    setHasTeacherJourneyLaunch(hasAccessToTeacherJourneyLaunch || false);
  }, [entitlementContext?.entitlements]);

  useEffect(() => {
    configureMenu(availableApps, availableData);
  }, [availableApps, availableData]);

  const configureMenu = (
    availableApps: AppNavItem[],
    availableData: AppNavItem[] | null
  ) => {
    const newWaffleGrid: WaffleGrid = parseLinksIntoGrid(
      availableApps,
      availableData
    );
    setWaffleGrid(newWaffleGrid);
  };

  const AppTile = ({
    displayName,
    imagePrefix,
  }: {
    displayName: string;
    imagePrefix?: string | undefined;
  }) => {
    return (
      <div className="app-tile">
        <div className="app-image">
          <WaffleIcon imagePrefix={imagePrefix} />
        </div>
        <div
          className={`${displayName === "Renaissance-U" ? "renu-text" : ""}`}
        >
          {displayName}
        </div>
      </div>
    );
  };
  return (
    <WaffleMenuContainer>
      <ul className="app-nav-menu">
        {frontendRenNextPane ? (
          <RenaissanceNextPane
            hasTeacherJourneyLaunch={hasTeacherJourneyLaunch}
            isWaffle={true}
          />
        ) : null}
        <div className="app-nav-grid">
          {waffleGrid.gridRows.map((gridRow, rowIndex) => (
            <div key={rowIndex} className="app-nav-grid-row">
              {gridRow.tiles.map((tile, tileIndex) => (
                <React.Fragment key={tileIndex}>
                  {!tile.children && (
                    <li onClick={() => navigateToLaunchApp(tile)}>
                      <AppTile
                        displayName={tile.displayName}
                        imagePrefix={tile.imagePrefix}
                      />
                    </li>
                  )}
                  {!!tile.children && (
                    <li onClick={() => openNavDialog(tile)}>
                      <AppTile
                        displayName={tile.displayName}
                        imagePrefix={tile.imagePrefix}
                      />
                    </li>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </ul>
    </WaffleMenuContainer>
  );
};

export default WaffleMenu;

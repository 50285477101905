import React from "react";
import { ReactComponent as Lalilo } from "../../assets/images/lalilo_logo.svg";
import { ReactComponent as Freckle } from "../../assets/images/freckle_logo.svg";
import { ReactComponent as AR } from "../../assets/images/ar_logo.svg";
import { ReactComponent as Reports } from "../../assets/images/reports_logo.svg";
import { ReactComponent as MyON } from "../../assets/images/myon_logo.svg";
import { ReactComponent as Star } from "../../assets/images/star_logo.svg";
import { ReactComponent as Nearpod } from "../../assets/images/nearpod_logo.svg";
import { ReactComponent as Schoolzilla } from "../../assets/images/schoolzilla_logo.svg";
import { ReactComponent as Renu } from "../../assets/images/ren_u_logo.svg";

interface IconProps {
  imagePrefix?: string | undefined;
  style?: React.CSSProperties;
}

const WaffleIcon: React.FC<IconProps> = ({ imagePrefix, style }) => {
  switch (imagePrefix) {
    case "freckle_logo":
      return <Freckle style={style} />;
    case "lalilo_logo":
      return <Lalilo style={style} />;
    case "myon_logo":
      return <MyON style={style} />;
    case "star_logo":
      return <Star style={style} />;
    case "nearpod_logo":
      return <Nearpod style={style} />;
    case "schoolzilla_logo":
      return <Schoolzilla style={style} />;
    case "ren_u_logo":
      return <Renu style={style} />;
    case "ar_logo":
      return <AR style={style} />;
    case "reports_logo":
      return <Reports style={style} />;
    default:
      return <></>;
  }
};

export default WaffleIcon;

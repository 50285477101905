import React from "react";
import { Alert } from "@mui/material";
import { t } from "i18next";
import { RAErrorButtonLink } from "../lib/button";
import { AuthCookieContext } from "../AuthCookieContext";
import { openLiveChat } from "../Utils";

export const PageRenderError = () => {
  const authCookieContext = React.useContext(AuthCookieContext);
  return (
    <Alert severity="error">
      Oops, we're unable to render this view. Please try again later or{" "}
      <RAErrorButtonLink
        onClick={() => openLiveChat(authCookieContext?.tenant_id)}
      >
        contact support
      </RAErrorButtonLink>{" "}
      if this issue persists.
    </Alert>
  );
};

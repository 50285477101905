import { jwtDecode } from "jwt-decode";
import { createContext } from "react";

export type AuthCookieContextType = {
  keepaliveuri?: string;
  logouturi?: string;
  timeoutminutes?: number;
  timetorespondminutes?: number;
  homeuri?: string;
  role?: string;
  customer_id?: number;
  user_id?: string;
  customer_name?: string;
  tenant_id?: string;
  countryCode?: "US" | "GB";
  email?: string;
  customercenterurl?: string;
  rid?: string;
};

export function getAuthCookieData(
  token: string
): AuthCookieContextType | undefined {
  const decoded = jwtDecode<AuthCookieContextType>(token);
  return decoded;
}

export const AuthCookieContext = createContext<AuthCookieContextType | null>(
  null
);

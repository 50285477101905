import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import ClipLoader from "react-spinners/ClipLoader";
import { Grid } from "@mui/material";

export const Spinner = ({ isLoading = false }) => {
  const { promiseInProgress } = usePromiseTracker();

  const loading = isLoading || promiseInProgress;

  return (
    <Grid container justifyContent="center">
      <ClipLoader
        loading={loading}
        size={100}
        cssOverride={{ alignSelf: "center" }}
        aria-label="Loading Spinner"
        data-testid="loader"
        color="#146eb3"
      />
    </Grid>
  );
};

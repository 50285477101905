import React from "react";
import { styled } from "@mui/system";
import { ReactComponent as BookK2 } from "../../assets/images/book_k-2.svg";
import WKARContainer from "./WKARContainer";
import { Grade, GradeLevelDataResponse } from "./StoriesWrapper";
import ARBooks from "./ARBooks";
import MyONBooks from "./MyONBooks";

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "right",
  width: "60%",
  marginRight: "var(--std-margin-lg)",
});

const Title = styled("h2")({
  fontSize: "var(--std-font-2xlg)",
  fontWeight: 400,
  margin: 0,
});

const SectionTitle = styled(Title)({
  alignSelf: "flex-end",
  justifySelf: "flex-end",
  color: "var(--tablegrey)",
});

const GradeTitle = styled(Title)({
  color: "var(--white)",
});

const DateText = styled("p")({
  color: "var(--grey)",
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: 0,
});

const BookSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "2 1 0%",
  maxWidth: "77%",
});

export const YourTopBooks = ({
  title,
  date,
  selectedGrade,
  gradeLevelData,
}: {
  title: string;
  date: string;
  selectedGrade: Grade;
  gradeLevelData: GradeLevelDataResponse;
}) => {
  if (!gradeLevelData || !selectedGrade) {
    return <div>Loading...</div>;
  }

  const gradeData = gradeLevelData[selectedGrade.grade_level];
  console.log(gradeData);

  if (!gradeData) {
    return <div>No data available for the selected grade.</div>;
  }

  const arBookData = gradeData.ar_books;
  const myonBookData = gradeData.myon_books;

  const arBooksToShow =
    arBookData.length > 0
      ? arBookData.slice(0, myonBookData.length > 0 ? 5 : 10)
      : [];
  const myonBooksToShow =
    myonBookData.length > 0
      ? myonBookData.slice(0, arBookData.length > 0 ? 5 : 10)
      : [];

  const beigeBarContent = (
    <>
      <BookK2
        style={{ width: "40%", justifyContent: "flex-end" }}
        aria-label="Book K-2"
      />
      <TextContainer>
        <DateText aria-label={`Date: ${date}`}>{date}</DateText>
        <SectionTitle aria-label={`Title: ${title}`}>{title}</SectionTitle>
      </TextContainer>
    </>
  );
  const redBarContent = (
    <GradeTitle style={{ color: "var(--white)" }}>
      {selectedGrade.grade_level_long}
    </GradeTitle>
  );
  return (
    <WKARContainer
      beigeBarWidth="75%"
      beigeBarContent={beigeBarContent}
      redBarContent={redBarContent}
    >
      <BookSection>
        {arBooksToShow && <ARBooks books={arBooksToShow} />}
        {myonBooksToShow && <MyONBooks books={myonBooksToShow} />}
      </BookSection>
    </WKARContainer>
  );
};

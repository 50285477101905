import React from "react";
import { styled } from "@mui/system";

const Container = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding:
    "var(--std-padding-lg) var(--std-padding-lg) 0 var(--std-padding-lg)",
  width: "100%",
  height: "92%",
});

const LeftContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 2,
  paddingRight: "var(--std-padding-lg)",
});

const Column = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const Row = styled("div")({
  display: "flex",
  flexDirection: "row",
});

const RightColumn = styled(Column)({
  border: "1px solid #E5E5E5",
  padding: "0 var(--std-padding-lg)",
});

const Image = styled("img")({
  width: "100%",
  height: "auto",
});

const Title = styled("h2")({
  color: "var(--red)",
  fontSize: "var(--std-font-2xlg)",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  margin: "var(--std-margin-md) 0 0 0",
});

const ParagraphContainer = styled("p")({
  color: "var(--tablegrey)",
});

const ExploreLayout2 = ({
  image1: Image1,
  image2: Image2,
  image3: Image3,
  title1,
  title2,
  title3,
  children,
}: {
  image1: React.FC;
  image2: React.FC;
  image3: React.FC;
  title1: string;
  title2: string;
  title3: string;
  children: React.ReactNode[];
}) => {
  return (
    <Container>
      <LeftContainer>
        <Row>
          <Image1 />
          <Column>
            <Title>{title1}</Title>
            <ParagraphContainer>{children[0]}</ParagraphContainer>
          </Column>
        </Row>
        <Row>
          <Image2 />
          <Column>
            <Title>{title2}</Title>
            <ParagraphContainer>{children[1]}</ParagraphContainer>
          </Column>
        </Row>
      </LeftContainer>
      <RightColumn>
        <Title>{title3}</Title>
        <ParagraphContainer>{children[2]}</ParagraphContainer>
        <Image3 />
      </RightColumn>
    </Container>
  );
};

export default ExploreLayout2;

import React, { useContext } from "react";
import { styled } from "@mui/system";
import { AuthCookieContext } from "../AuthCookieContext";

const Container = styled("div")`
  width: 130px;
  height: 33px;
  flex-shrink: 0;
  border-radius: 3px;
  background: var(--lightgrey);
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 2px;
`;

const LeaderButton = styled("button")`
  appearance: none;
  border: none;
  padding: 0;
  display: flex;
  width: 60px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
`;

const TeacherButton = styled("button")`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 60px;
  height: 24px;
  color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--tabledividergrey);
    border-radius: 5px;
    color: var(--tablegrey);
  }
`;

const ButtonLabel = styled("p")`
  text-align: center;
  font-size: var(--std-font-xs);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TeacherJourneyToggle: React.FC = () => {
  const authCookieContext = useContext(AuthCookieContext);
  const handleNavigate = () => {
    // navigate to teacher journey
    window.location.href = `${authCookieContext?.homeuri}launch?task=NEXT_TEACHER_AS_ADMIN&applicationId=0`;
  };

  return (
    <Container data-testid="teacher-journey-toggle">
      <LeaderButton aria-pressed={true}>
        <ButtonLabel>Leader</ButtonLabel>
      </LeaderButton>
      <TeacherButton onClick={handleNavigate}>
        <ButtonLabel>Teacher</ButtonLabel>
      </TeacherButton>
    </Container>
  );
};

export default TeacherJourneyToggle;

import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/system";
import { LeftNavCollapsed } from "./LeftNavCollapsed";
import { LeftNavExpanded } from "./LeftNavExpanded";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow_right_circle.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow_left_circle.svg";
import { EntitlementContext } from "../EntitlementContext";
import { AppNavItem } from "../Waffle/WaffleMenu";
import { AppLinkSelector } from "../Waffle/AppLinkSelector/AppLinkSelector";
import {
  getAvailableAppsForWaffle,
  getAvailableDataForWaffle,
} from "../Waffle/ConvertEntitlements";
import { AuthCookieContext } from "../AuthCookieContext";

const StyledDiv = styled("div")<{ isLeftNavExpanded: boolean }>`
  height: 100;
  flex-shrink: 0;
  z-index: 998;
  transition: width 0.5s ease-in-out;
  border-right: 1px solid #cccccc;
  width: ${(props) => (props.isLeftNavExpanded ? "250px" : "66.25px")};
  margin-top: -15px;
`;

const StyledArrowButton = styled("button")`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 68px;
  width: 34px;
  height: 34px;
  transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
  &.left {
    left: 250px;
  }
  &.right {
    left: 68px;
  }
`;

const AnimatedLeftNavExpanded = styled(LeftNavExpanded)`
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease-in-out;
  &.visible {
    display: block;
    opacity: 1;
  }
`;

const AnimatedLeftNavCollapsed = styled(LeftNavCollapsed)`
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease-in-out;
  &.visible {
    display: block;
    opacity: 1;
  }
`;

export const LeftNavWrapper = ({
  isLeftNavExpanded,
  setIsLeftNavExpanded,
}: {
  isLeftNavExpanded: boolean;
  setIsLeftNavExpanded: (isExpanded: boolean) => void;
}) => {
  const toggleExpanded = () => {
    setIsLeftNavExpanded(!isLeftNavExpanded);
  };

  const entitlementContext = React.useContext(EntitlementContext);
  const authCookieContext = React.useContext(AuthCookieContext);
  const [availableApps, setAvailableApps] = React.useState<AppNavItem[]>([]);
  const [availableData, setAvailableData] = React.useState<AppNavItem[]>([]);
  const [hasTeacherJourneyLaunch, setHasTeacherJourneyLaunch] = useState(false);
  const [showSubmenu, setShowSubmenu] = React.useState(false);
  const [selectedAppNavItem, setSelectedAppNavItem] =
    React.useState<AppNavItem | null>(null);

  const baseUrl = authCookieContext?.homeuri
    ? authCookieContext.homeuri
    : process.env.REACT_APP_RL_LAUNCH_URL;

  const navigateToLaunchApp = (appNavItem: AppNavItem) => {
    if (appNavItem.url) {
      window.location.href = appNavItem.url!;
    } else if (appNavItem.applicationId && appNavItem.taskId) {
      window.location.href = `${baseUrl}launch?task=${
        appNavItem.taskId
      }&applicationId=${appNavItem.applicationId}${
        appNavItem.deepLinkId ? `&deepLinkId=${appNavItem.deepLinkId}` : ""
      }`;
    }
  };

  const itemClicked = (appNavItem: AppNavItem) => {
    if (appNavItem.children) {
      setShowSubmenu(true);
      setSelectedAppNavItem(appNavItem);
    } else {
      navigateToLaunchApp(appNavItem);
    }
  };

  React.useEffect(() => {
    const waffleApps = getAvailableAppsForWaffle(
      false,
      entitlementContext?.entitlements
    );
    setAvailableApps(waffleApps);
    const waffleData = getAvailableDataForWaffle(
      false,
      entitlementContext?.entitlements
    );
    setAvailableData(waffleData);
    // find out if the user has access to the teacher journey launch by looking in the entitlements for applicationId: 0, taskId: NEXT_TEACHER_AS_ADMIN
    const hasAccessToTeacherJourneyLaunch =
      entitlementContext?.entitlements?.some(
        (entitlement) =>
          entitlement.applicationId === "0" &&
          entitlement.taskId === "NEXT_TEACHER_AS_ADMIN"
      );
    setHasTeacherJourneyLaunch(hasAccessToTeacherJourneyLaunch || false);
  }, [entitlementContext?.entitlements]);

  return (
    <nav aria-label="Left Navigation">
      <StyledDiv isLeftNavExpanded={isLeftNavExpanded}>
        <StyledArrowButton
          onClick={toggleExpanded}
          className={isLeftNavExpanded ? "left" : "right"}
        >
          {isLeftNavExpanded ? <ArrowLeft /> : <ArrowRight />}
        </StyledArrowButton>
        <AnimatedLeftNavCollapsed
          availableApps={availableApps}
          availableData={availableData}
          itemClicked={itemClicked}
          className={isLeftNavExpanded ? "" : "visible"}
        />
        <AnimatedLeftNavExpanded
          availableApps={availableApps}
          availableData={availableData}
          hasTeacherJourneyLaunch={hasTeacherJourneyLaunch}
          itemClicked={itemClicked}
          className={isLeftNavExpanded ? "visible" : ""}
        />
      </StyledDiv>
      {selectedAppNavItem ? (
        <Drawer open={showSubmenu} onClose={() => setShowSubmenu(false)}>
          <AppLinkSelector
            closeSubMenuDialog={() => setShowSubmenu(false)}
            appNavItem={selectedAppNavItem}
            navigateToLaunchApp={navigateToLaunchApp}
            isLeftNav={true}
          />
        </Drawer>
      ) : null}
    </nav>
  );
};

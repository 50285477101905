import React from "react";
import { styled } from "@mui/system";
import WaffleIcon from "../WaffleIcon";
import { DialogContent, Accordion } from "@mui/material";
import { AppLinkSelectorOption } from "./AppLinkSelectorOption";
import { AppNavItemIds, AppNavItem } from "../WaffleMenu";

const map = require("lodash/map");

export const AppLinkSelector = ({
  closeSubMenuDialog,
  appNavItem,
  navigateToLaunchApp,
  isLeftNav,
}: {
  closeSubMenuDialog: () => void;
  appNavItem: AppNavItem;
  navigateToLaunchApp: (appNavItem: AppNavItem) => void;
  isLeftNav?: boolean;
}) => {
  const LinkSelectorContainer = styled("div")<{ isLeftNav?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 333px;
    max-height: ${(props) => (props.isLeftNav ? "none" : "800px")};
    overflow-y: auto;
  `;

  const LinkSelectorHeader = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: var(--std-padding-lg);
    padding-right: var(--std-padding-lg);
  `;

  const LinkSelectorHeaderProduct = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: left;
  `;

  const LinkSelectorHeaderLink = styled("div")`
    justify-content: left;
  `;

  const HeaderText = styled("div")`
    line-height: 40px;
    vertical-align: middle;
    font-size: 15px;
    color: var(--tablegrey);
    padding: 0;
  `;

  const HeaderCloseButton = styled("div")`
    margin-left: auto;
    text-decoration: none;
    font-size: 13px;
    color: var(--blue);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `;

  const getHeaderText = (appNavItem: any) => {
    return appNavItem.longDisplayName || appNavItem.displayName;
  };

  const isDefaultExpanded = appNavItem.id === AppNavItemIds.AR.toLowerCase();

  return (
    <LinkSelectorContainer
      data-cy="app-link-selector-container"
      isLeftNav={isLeftNav}
    >
      <LinkSelectorHeader>
        <LinkSelectorHeaderProduct>
          <WaffleIcon
            imagePrefix={appNavItem.imagePrefix}
            style={{ height: "40px", marginRight: "var(--std-margin-xs)" }}
          />
          <HeaderText>{getHeaderText(appNavItem)}</HeaderText>
        </LinkSelectorHeaderProduct>
        <LinkSelectorHeaderLink>
          <HeaderCloseButton
            onClick={closeSubMenuDialog}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                closeSubMenuDialog();
              }
            }}
          >
            Close
          </HeaderCloseButton>
        </LinkSelectorHeaderLink>
      </LinkSelectorHeader>
      <DialogContent sx={{ padding: 0 }}>
        {map(appNavItem.children, (child: any) => {
          return (
            <Accordion style={{ boxShadow: "none" }}>
              <AppLinkSelectorOption
                appNavItem={child}
                navigateToLaunchApp={navigateToLaunchApp}
                isDefaultExpanded={isDefaultExpanded}
              />
              ;
            </Accordion>
          );
        })}
      </DialogContent>
    </LinkSelectorContainer>
  );
};

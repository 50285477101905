import React from "react";
import { styled } from "@mui/system";
import WKARContainer from "./WKARContainer";
import { Grade } from "./StoriesWrapper";
import GradeKEnUS from "./GradeKEnUS";
import Grade3EnUS from "./Grade3EnUS";
import Grade6EnUS from "./Grade6EnUS";
import Grade9EnUS from "./Grade9EnUS";
import Grade12EnUS from "./Grade12EnUS";
import Grade1EnUS from "./Grade1EnUS";
import Grade4EnUS from "./Grade4EnUS";
import Grade7EnUS from "./Grade7EnUS";
import Grade10EnUS from "./Grade10EnUS";

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "var(--std-margin-xlg)",
  width: "65%",
});

const Title = styled("h2")({
  fontSize: "var(--std-font-2xlg)",
  fontWeight: 400,
  margin: 0,
});

const SectionTitle = styled(Title)({
  color: "var(--tablegrey)",
});

export const Explore = ({ selectedGrade }: { selectedGrade: Grade }) => {
  const beigeBarContent = (
    <>
      <TextContainer>
        <SectionTitle
          aria-label={`Explore books to inspire every ${selectedGrade.grade_level} reader`}
        >
          Explore books to inspire every{" "}
          <strong>{selectedGrade.grade_level_long}</strong> Reader
        </SectionTitle>
      </TextContainer>
    </>
  );
  const gradeContent = () => {
    switch (selectedGrade.grade_level_numeric) {
      case 0:
        return <GradeKEnUS />;
      case 1:
        return <Grade1EnUS />;
      case 3:
        return <Grade3EnUS />;
      case 4:
        return <Grade4EnUS />;
      case 6:
        return <Grade6EnUS />;
      case 7:
        return <Grade7EnUS />;
      case 9:
        return <Grade9EnUS />;
      case 10:
        return <Grade10EnUS />;
      case 12:
        return <Grade12EnUS />;
      default:
        return null;
    }
  };
  return (
    <WKARContainer
      beigeBarWidth="100%"
      beigeBarContent={beigeBarContent}
      redBarContent={null}
    >
      {gradeContent()}
    </WKARContainer>
  );
};

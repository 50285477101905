import { useEffect } from "react";

interface PendoTrackProps {
  eventType: string;
  eventData: any;
}

const PendoTrack = ({ eventType, eventData }: PendoTrackProps) => {
  if (window && (window as any).pendo) {
    (window as any).pendo.track(eventType, eventData);
  }
};

export default PendoTrack;

import React from "react";
import ExploreLayout2 from "./ExploreLayout2";
import { styled } from "@mui/system";
import { ReactComponent as RR } from "../../assets/images/books_10-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_10-SP.svg";
import { ReactComponent as HLC } from "../../assets/images/books_10-HLC.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade10EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Authentic Spanish";
  const title3 = "High-Level Contemporary";

  return (
    <ExploreLayout2
      image1={RR}
      image2={SP}
      image3={HLC}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>A Glasshouse of Stars</strong> • Shirley Marr • 5.8, MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Ugly</strong> • Robert Hoge • 5.7, MG • NF
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Ojos del jaguar</strong> • Alma Flor Ada • 7.6, MG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Yaqui Delgado quiere darte una paliza</strong> • Meg Medina •
          4.2, UG • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          High-level books written by contemporary authors may bridge the gap
          between self-selected titles and curricular reading.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Wisdom's Kiss</strong> • Catherine Gilbert Murdock • 8.8 MG+
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Hidden Figures</strong> • Margot Lee Shetterly • 9.7, UG • NF
        </AdditionalParagraph>
      </div>
    </ExploreLayout2>
  );
};

export default Grade10EnUS;

import React from "react";
import ExploreLayout2 from "./ExploreLayout2";
import { styled } from "@mui/system";
import { ReactComponent as RR } from "../../assets/images/books_1-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_1-SP.svg";
import { ReactComponent as FF } from "../../assets/images/books_1-FF.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade1EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Authentic Spanish";
  const title3 = "Futuristic Finds";

  return (
    <ExploreLayout2
      image1={RR}
      image2={SP}
      image3={FF}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>The Patchwork Bike</strong> • Maxine Beneba Clarke • 2.9, LG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>
            This Very Tree: A Story of 9/11, Resilience, and Regrowth
          </strong>{" "}
          • Sean Rubin • 2.9, LG • <strong>NF</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph>
          <strong>El vecindario de Quinito (libro bilingüe)</strong> • Ina
          Cumpiano • 2.9, LG • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>¡Toca, Chavi, toca! (libro bilingüe)</strong> • Mayra L. Dole
          • 3.1, LG • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Set the stage for inspiration and innovation, opening kids’ minds to
          question “what if” and imagine all that is possible.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Boy + Bot</strong> • Ame Dyckman • 1.2, LG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Clink</strong> • Kelly DiPucchio • 3.1, LG
        </AdditionalParagraph>
      </div>
    </ExploreLayout2>
  );
};

export default Grade1EnUS;

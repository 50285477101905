import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { HelpOutlineRounded } from "@mui/icons-material";
import { sentenceCase } from "./Utils";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "var(--black)",
    fontSize: "var(--std-font-xs)",
    border: "var(--std-border-tooltip)",
    padding: "var(--std-margin-md)",
  },
}));

export function TitleTooltip({
  title,
  definition,
  source,
  size = "regular",
}: Props) {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography>{title}</Typography>
          <Typography style={{ fontSize: "var(--std-font-xs)" }}>
            {sentenceCase(definition)}
          </Typography>
          <Typography
            style={{
              fontSize: "var(--std-font-xs)",
              marginTop: "var(--std-margin-xs)",
            }}
          >
            {source ? `Source: ${source}` : ""}
          </Typography>
        </React.Fragment>
      }
    >
      <HelpOutlineRounded
        sx={{
          color: "var(--black)",
          "&:hover": {
            color: "var(--blue)",
          },
          fontSize:
            size === "small" ? "var(--std-font-lg)" : "var(--std-font-xlg)",
          marginBottom: size === "small" ? "-5px" : "-3px", // the larger value is for the OptInOptOut component
        }}
      />
    </HtmlTooltip>
  );
}

export interface Props {
  title: string;
  definition: string;
  source?: string;
  size?: string;
}

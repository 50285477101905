import React from "react";
import { styled } from "@mui/system";
import BookContainer from "./BookContainer";
import { Book } from "./StoriesWrapper";
import { ReactComponent as MyONLogo } from "../../assets/images/myON_logo-WKAR.svg";

interface MyONBooksProps {
  books: Book[];
}

const Container = styled("div")({
  borderRadius: "14px",
  border: "1px solid #E5E5E5", // not a current grey in our library
  padding: "var(--std-padding-md)",
  display: "flex",
  flexDirection: "column",
  margin: "var(--std-margin-lg)",
});

const Image = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
  marginBottom: "var(--std-margin-md)",
});

const MyONBooks: React.FC<MyONBooksProps> = ({ books }) => {
  return (
    <Container>
      <MyONLogo />
      <BookContainer books={books} />
    </Container>
  );
};

export default MyONBooks;

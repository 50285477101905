import React from "react";
import { styled } from "@mui/system";
import WKARPanelLogo from "../../assets/images/wkar_panel_logo.png";
import { useTranslation, Trans } from "react-i18next";
import OverallMetricsBooks from "./OverallMetricsBooks";
import OverallMetricsFictionNonfiction from "./OverallMetricsFictionNonfiction";
import OverallMetricsWordsRead from "./OverallMetricsWordsRead";
import { CustomerMetadata, OverallStats, School } from "./StoriesWrapper";

const Container = styled("div")({
  width: "100%",
  maxWidth: "1031px",
  borderRadius: "20px",
  background: "var(--white)",
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${WKARPanelLogo})`,
  height: "300px",
  borderRadius: "20px 20px 0 0",
});

const Title = styled("h2")({
  color: "var(--white)",
  textShadow: "0px 2.463px 4.925px rgba(0, 0, 0, 0.55)",
  fontSize: "var(--std-font-4xlg)",
  fontWeight: 400,
  lineHeight: "120%",
  margin: 0,
  width: "409px",
});

const PreTitle = styled("p")({
  color: "var(--white)",
  textShadow: "0px 4.925px 4.925px rgba(0, 0, 0, 0.25)",
  fontSize: "var(--std-font-lg)",
  fontWeight: 700,
  lineHeight: "36.939px", // 214.286%
  margin: 0,
  textTransform: "uppercase",
});

const SubTitle = styled("p")({
  color: "var(--white)",
  textShadow: "0px 4.925px 4.925px rgba(0, 0, 0, 0.25)",
  fontSize: "var(--std-font-lg)",
  fontWeight: 300,
  lineHeight: "36.939px",
  margin: 0,
});

const HeaderTextDiv = styled("div")({
  margin: "var(--std-margin-3xl) 0 0 78px",
  display: "flex",
  flexDirection: "column",
});

const OverallMetricImagesAndValuesDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  alignItems: "center",
  margin: "0 var(--std-margin-2xl)",
  "& svg": {
    width: "230px",
  },
  "& div": {
    flex: "1",
  },
});

const OverallMetricTitle = styled("h3")({
  color: "var(--red)",
  textAlign: "center",
  fontSize: "var(--std-font-xlg)",
  fontWeight: 300,
  margin: "var(--std-margin-sm) 0 var(--std-margin-2xl) 0",
});

const EmphasizedText = styled("span")({
  fontStyle: "italic",
  fontWeight: "500",
});

interface HeaderContentProps {
  products: string[] | undefined;
}

const HeaderContent: React.FC<HeaderContentProps> = ({ products }) => {
  const { t } = useTranslation();

  const productMap: { [key: string]: string } = {
    ar: "AR",
    myon: "myON",
  };

  const mappedProducts = products?.map(
    (product) => productMap[product] || product
  );
  const subtitleText = mappedProducts?.join(" and ");

  return (
    <Header>
      <HeaderTextDiv>
        <PreTitle data-cy="featured-story">
          {t("TEXT_WKAR_OVERALL_METRICS.FEATURED_STORY")}
        </PreTitle>
        <Title data-cy="title">
          <Trans
            i18nKey="TEXT_WKAR_OVERALL_METRICS.TITLE"
            components={{ 1: <EmphasizedText /> }}
          />
        </Title>
        <SubTitle data-cy="subtitle">
          <Trans
            i18nKey="TEXT_WKAR_OVERALL_METRICS.SUBTITLE"
            values={{ products: subtitleText }}
          />
        </SubTitle>
      </HeaderTextDiv>
    </Header>
  );
};

interface OverallMetricsProps {
  products: string[] | undefined;
  school: School;
  overallStats: OverallStats;
}

const OverallMetrics: React.FC<OverallMetricsProps> = ({
  products,
  school,
  overallStats,
}) => {
  const { t } = useTranslation();
  // first, check if school.id has "_district" in it
  // if it does, then it's a district and we use the district object in overallStats
  // if it doesn't, then it's a school and we find the school in overallStats.schools by id
  const stats = school.id.includes("_district")
    ? overallStats.district
    : overallStats.schools[school.id];

  return stats ? (
    <Container data-cy="overall-stats">
      <HeaderContent products={products} />
      <OverallMetricTitle data-cy="school-name">
        {school.name}
      </OverallMetricTitle>
      <OverallMetricImagesAndValuesDiv>
        <OverallMetricsBooks value={stats.books_read_count} />
        <OverallMetricsFictionNonfiction
          fictionPercentage={100 - stats.nonfiction_percent}
          nonfictionPercentage={stats.nonfiction_percent}
        />
        <OverallMetricsWordsRead value={stats.words_read_count} />
      </OverallMetricImagesAndValuesDiv>
    </Container>
  ) : (
    <></>
  );
};

export default OverallMetrics;

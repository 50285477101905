import React, { useState, forwardRef } from "react";
import ModalTemplate from "./ModalTemplate";
import { TextField } from "@mui/material";

interface OptOutModalProps {
  handleOptOut: (feedback?: string) => void;
  handleClose: (feedback?: string) => void;
}

const OptOutModal = forwardRef<HTMLDivElement, OptOutModalProps>(
  ({ handleOptOut, handleClose }, ref) => {
    const [feedback, setFeedback] = useState("");

    const title = "Switch Back to the Legacy Home Page";
    const description =
      "We'd love to hear what worked for you and what didn't so that we can better meet your needs.";
    const buttonDescription =
      "You will be directed to the Legacy Home page when you switch back.";
    const buttonSecondaryText = "Cancel";
    const buttonPrimaryText = "Switch Back";
    const middleElement = (
      <TextField
        style={{ width: "658px" }}
        placeholder="Enter feedback..."
        multiline
        rows={6}
        maxRows={6}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
    );

    return (
      <div ref={ref}>
        <ModalTemplate
          title={title}
          description={description}
          buttonDescription={buttonDescription}
          buttonSecondaryText={buttonSecondaryText}
          buttonPrimaryText={buttonPrimaryText}
          handleSecondaryAction={() => handleClose(feedback)}
          handlePrimaryAction={() => handleOptOut(feedback)}
          middleElement={middleElement}
        />
      </div>
    );
  }
);

export default OptOutModal;

import React from "react";
import { Button, styled, IconButton } from "@mui/material";

export const RAButton = styled(Button)(({ theme }) => ({
  backgroundColor: "var(--blue)",
  color: "white",
  borderColor: "var(--grey)",
  "&:hover": {
    backgroundColor: "var(--transparent-blue)",
    color: "var(--grey)",
    borderColor: "var(--blue)",
  },
}));

// TODO: There is a way to define this as a variant, but I haven't quite figured that out
export const RAButtonSecondary = styled(Button)(({ theme }) => ({
  color: "var(--blue)",
  "&:hover": {
    color: "var(--grey)",
    borderColor: "var(--blue)",
  },
}));

export const RAButtonLink = styled(Button)(({ theme }) => ({
  color: "var(--grey)",
  "&:hover": {
    color: "var(--blue)",
    borderColor: "var(--blue)",
  },
}));

export const RAErrorButtonLink = styled(Button)(({ theme }) => ({
  color: "var(--blue)",
  textTransform: "lowercase",
  padding: 0,
  marginBottom: "2px",
  fontSize: "inherit",
  "&:hover": {
    borderColor: "var(--blue)",
  },
}));

export const RAIconButton = styled(IconButton)(({ theme }) => ({
  color: "var(--grey)",
  marginTop: "-22px",
  "&:hover": {
    backgroundColor: "var(--transparent-blue)",
  },
}));

export const RATileIconButton = styled("button")(
  ({
    isActive = false,
    isHover = false,
  }: {
    isActive?: boolean;
    isHover?: boolean;
  }) => ({
    width: "33px",
    height: "33px",
    borderRadius: "50%",
    padding: 0,
    outline: "inherit",
    border: "2px solid transparent",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "var(--beige)",
      border: "2px solid var(--blue)",
      cursor: "pointer",
    },
    ...(isActive === true && {
      backgroundColor: "var(--beige)",
    }),
  })
);

export const RATileIcon = styled("svg")(
  ({ isActive = false }: { isActive?: boolean }) => ({
    margin: "auto",
    display: "block",
    width: "33px",
    height: "33px",
    marginLeft: "-2px",
    marginTop: "-2px",
    "&:hover, &:hover path, &:hover circle": {
      stroke: "var(--black) !important",
    },
    "&:hover rect": {
      fill: "var(--black) !important",
    },
    ...(isActive === true && {
      "path, circle, rect": {
        stroke: "var(--black) !important",
      },
      rect: {
        fill: "var(--black) !important",
      },
    }),
  })
);

export const UnstyledButton = styled("button")`
  background: none;
  border: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  max-width: 50px;
  .icon-container,
  svg {
    display: block;
    height: 35px;
  }
`;

export const LongButton = styled("button")(
  ({ hoverBorderColor = "var(--blue)" }: { hoverBorderColor?: string }) => ({
    height: "40px",
    borderRadius: "20px",
    border: "2px solid var(--grey)",
    padding: "10px 25px",
    background: "var(--white)",
    marginLeft: "var(--std-margin-md)",
    fontWeight: "500",
    "&:hover": {
      background: "var(--beige)",
      border: `2px solid ${hoverBorderColor}`,
      cursor: "pointer",
    },
    "&:disabled": {
      pointerEvents: "none",
      opacity: "0.65",
      filter: "alpha(opacity=65)",
    },
  })
);

export const LongButtonPrimary = styled("button")(() => ({
  height: "40px",
  borderRadius: "20px",
  border: "2px solid var(--blue)",
  padding: "10px 25px",
  background: "var(--white)",
  fontWeight: "500",
  backgroundColor: "var(--blue)",
  color: "var(--white)",
  cursor: "pointer",
}));

export const LongButtonSecondary = styled("button")(() => ({
  height: "40px",
  borderRadius: "20px",
  border: "2px solid var(--blue)",
  padding: "10px 25px",
  background: "var(--white)",
  fontWeight: "500",
  color: "var(--blue)",
  cursor: "pointer",
  textWrap: "nowrap",
}));

export const ToggleButton = styled("button")(
  ({ isActive = false }: { isActive?: boolean }) => ({
    border: "1px solid var(--lightgrey)",
    width: "140px",
    height: "40px",
    backgroundColor: "var(--white)",
    color: "var(--grey)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "var(--std-border-radius)",
    svg: {
      width: "30px",
      marginRight: "var(--std-margin-xxs)",
    },
    "&:hover": {
      cursor: "pointer",
    },
    ...(isActive === true && {
      backgroundColor: "var(--more-transparent-blue)",
      color: "var(--black)",
      border: "1px solid transparent",
      fontWeight: "bold",
    }),
    "&.left-button": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&.right-button": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  })
);

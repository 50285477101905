import React, { Component, ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
  fallback: ReactNode;
  location: any;
}

interface State {
  hasError: boolean;
}

export const useThrowAsyncError = () => {
  const [state, setState] = useState();

  return (error: Error) => {
    setState(() => {
      throw error;
    });
  };
};

export class PageErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Props) {
    // Reset the error state if the location changes
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  // we can added error logging here if we want
  // public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  // }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { LongButtonSecondary, LongButtonPrimary } from "../lib/button";

const Title = styled("h1")`
  color: var(--tablegrey);
  font-feature-settings: "clig" off, "liga" off;
  font-size: var(--std-font-xlg);
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  margin-top: 0;
`;

const Description = styled("p")`
  color: var(--tablegrey);
  font-size: var(--std-font-md);
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const ButtonDescription = styled(Description)`
  margin-bottom: 0;
`;

const ButtonsAndDescDiv = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: var(--std-margin-xlg);
`;

const ButtonsDiv = styled("div")`
  display: flex;
  gap: 10px;
`;

interface ModalProps {
  title: string;
  description: string;
  buttonDescription: string;
  buttonSecondaryText: string;
  buttonPrimaryText: string;
  handleSecondaryAction: () => void;
  handlePrimaryAction: () => void;
  middleElement: React.ReactNode;
}

const ModalTemplate = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      title,
      description,
      buttonDescription,
      buttonSecondaryText,
      buttonPrimaryText,
      handleSecondaryAction,
      handlePrimaryAction,
      middleElement,
    },
    ref
  ) => {
    return (
      <div ref={ref}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "660px",
          }}
        >
          <Title>{title}</Title>
          <Description>{description}</Description>
          {middleElement}
          <ButtonsAndDescDiv>
            <ButtonDescription>{buttonDescription}</ButtonDescription>
            <ButtonsDiv>
              <LongButtonSecondary onClick={handleSecondaryAction}>
                {buttonSecondaryText}
              </LongButtonSecondary>
              <LongButtonPrimary onClick={handlePrimaryAction}>
                {buttonPrimaryText}
              </LongButtonPrimary>
            </ButtonsDiv>
          </ButtonsAndDescDiv>
        </Box>
      </div>
    );
  }
);
export default React.memo(ModalTemplate);

import { styled, keyframes } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const PageBackground = styled(Grid)(({ theme }) => ({
  backgroundColor: "var(--lightgrey)",
  padding: "var(--std-padding-md)",
  minHeight: "90vh",
  display: "flex",
}));

export const fadeInSlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const chartRed = "rgb(237, 76, 61)";
export const chartGreen = "rgb(34, 183, 154);";
export const chartGrey = "grey";

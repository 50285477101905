import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/public-sans";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { Spa } from "@newrelic/browser-agent/features/spa";
import { JSErrors } from "@newrelic/browser-agent/features/jserrors";

// NewRelic Tracing
const nrOptions = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.nr-data.net"] },
    spa: { enabled: true },
    jserrors: { enabled: true },
  },
  info: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: "4eb33e9837",
    applicationID: process.env.REACT_APP_NEW_RELIC_APP_ID,
    sa: 1,
  },
  loader_config: {
    accountID: "2468859",
    trustKey: "2940311",
    agentID: process.env.REACT_APP_NEW_RELIC_APP_ID,
    licenseKey: "4eb33e9837",
    applicationID: process.env.REACT_APP_NEW_RELIC_APP_ID,
  },
  features: [Spa, JSErrors],
};
new BrowserAgent(nrOptions);

const Highcharts = require("highcharts");

(function (apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ["initialize", "identify", "updateOptions", "pageLoad"];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
})(process.env.REACT_APP_PENDO_API_KEY);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

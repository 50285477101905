import React, { useState, useRef } from "react";
import { styled } from "@mui/system";
import LeftNavIcon from "./LeftNavIcon";
import { AppNavItem } from "../Waffle/WaffleMenu";

const StyledDiv = styled("div")`
  flex-shrink: 0;
  z-index: 998;
  cursor: pointer;
  padding-top: var(--std-padding-2xl);
`;

const AppIcon = ({
  app,
  itemClicked,
}: {
  app: AppNavItem;
  itemClicked: (appNavItem: AppNavItem) => void;
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  if (
    isHovered &&
    divRef.current &&
    divRef.current.matches(":hover") === false
  ) {
    setIsHovered(false);
  }

  return (
    <div
      ref={divRef}
      key={app.displayName}
      onClick={() => itemClicked(app)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="app-icon"
    >
      <LeftNavIcon imagePrefix={app.imagePrefix} isHovered={isHovered} />
    </div>
  );
};

export const LeftNavCollapsed = ({
  availableApps,
  availableData,
  itemClicked,
  className,
}: {
  availableApps: AppNavItem[];
  availableData: AppNavItem[] | null;
  itemClicked: (appNavItem: AppNavItem) => void;
  className?: string;
}) => {
  return (
    <StyledDiv className={className}>
      {availableData?.map((app: AppNavItem) => {
        return (
          <AppIcon key={app.displayName} app={app} itemClicked={itemClicked} />
        );
      })}
      {availableApps.map((app: AppNavItem) => {
        return (
          <AppIcon key={app.displayName} app={app} itemClicked={itemClicked} />
        );
      })}
    </StyledDiv>
  );
};

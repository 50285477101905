import React, { useEffect } from "react";
import Select from "react-select";
import { CheckboxOptionT } from "./Multiselect";
import { group } from "console";

interface Data {
  label: string;
  value: string;
  options?: CheckboxOptionT[];
}

type SingleSelectProps = {
  id?: string;
  options: Data[];
  onSelectedChange: (selected: string) => void;
  selectOptionsText: string;
  selected?: string;
  disabled?: boolean;
  ariaLabel?: string;
  isClearable?: boolean;
};

// this single select handles two of our four select cases:
// 1. selecting a single value from a list
// 2. selecting a single value from a nested list of category/values

const SingleSelect: React.FC<SingleSelectProps> = ({
  id,
  options,
  onSelectedChange,
  selectOptionsText,
  selected,
  disabled = false,
  ariaLabel,
  isClearable = true,
}) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 400,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  // if our options have options, we're in a grouped single select
  let currentValue: Data | undefined;
  if (options.length > 0 && options[0].hasOwnProperty("options")) {
    for (let i = 0; i < options.length; i++) {
      const groupOptions = options[i].options!;
      currentValue = groupOptions.find((option) => option.value === selected);
      if (currentValue) break;
    }
  } else {
    currentValue = options.find((option) => option.value === selected);
  }

  return (
    <Select
      key={selected}
      isDisabled={disabled}
      isClearable={isClearable}
      name={selectOptionsText}
      value={currentValue}
      options={options}
      placeholder={selectOptionsText}
      onChange={(selected) => {
        if (selected) {
          onSelectedChange(selected.value);
        } else {
          onSelectedChange("");
        }
      }}
      styles={customStyles}
      aria-label={ariaLabel}
    />
  );
};

export default SingleSelect;

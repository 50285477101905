import React from "react";
import ExploreLayout1 from "./ExploreLayout1";
import { styled } from "@mui/system";
import { ReactComponent as SP } from "../../assets/images/books_K-SP.svg";
import { ReactComponent as RR } from "../../assets/images/books_K-RR.svg";
import { ReactComponent as STEM } from "../../assets/images/books_K-STEM.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const GradeKEnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Popular STEM Books";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout1
      image1={RR}
      image2={STEM}
      image3={SP}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Big</strong> • Vashti Harrison • 1.7, LG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>
            Where Butterflies Fill the Sky: A Story of Immigration, Family, and
            Finding Home
          </strong>{" "}
          Zahra Marwan • 2.1, LG • <strong>NF</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Consider guiding students to science, technology, engineering, and
          math (STEM) topics, which may help spark their natural curiosity and
          echo individual interests.{" "}
        </Paragraph>
        <AdditionalParagraph>
          <strong>From Head to Toe</strong> • Eric Carle • 1.0, LG •{" "}
          <strong>NF</strong> • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Loose Tooth</strong> • Lola M. Schaefer • 0.9, LG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Llámame árbol (libro bilingüe)</strong> • Maya Christina
          González • 1.2, LG • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>
            Quizás algo hermoso: Cómo el arte transformó un barrio
          </strong>{" "}
          • F. Isabel Campoy • 2.4, LG • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout1>
  );
};

export default GradeKEnUS;

import React, { useState, useEffect } from "react";
import Stories from "./Stories";

export interface Grade {
  grade_level: string;
  grade_level_numeric: number;
  grade_level_long: string;
}

export interface School {
  id: string;
  name: string;
  products: string[];
  grades: Grade[];
}

interface District {
  id: string;
  name: string;
  products: string[];
  grades: Grade[];
}

export interface CustomerMetadata {
  district: District;
  schools: {
    [id: string]: School;
  };
}

interface SchoolStats {
  books_read_count: number;
  words_read_count: number;
  nonfiction_percent: number;
}

export interface OverallStats {
  district: SchoolStats;
  schools: {
    [id: string]: SchoolStats;
  };
}

export interface Book {
  cover_url: string;
  title: string;
  author: string;
  tags: string[];
}

export interface GradeLevelData {
  students_count: number;
  schools_count: number;
  nonfiction_percent: number;
  books_read_avg: number;
  words_read_avg: number;
  atos_level_avg: number;
  top_authors: string[];
  ar_books: Book[];
  myon_books: Book[];
}

export interface GradeLevelDataResponse {
  [grade: string]: GradeLevelData;
}

const StoriesWrapper: React.FC = () => {
  const [customerMetadata, setCustomerMetadata] =
    useState<CustomerMetadata | null>(null);
  const [overallStats, setOverallStats] = useState<OverallStats | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<School | undefined>(
    customerMetadata?.schools
      ? Object.values(customerMetadata.schools)[0]
      : undefined
  );
  const [gradeLevelData, setGradeLevelData] =
    useState<GradeLevelDataResponse | null>(null);
  const [selectedGrade, setSelectedGrade] = useState<Grade | undefined>(
    customerMetadata?.schools &&
      Object.values(customerMetadata.schools)[0].grades?.length
      ? Object.values(customerMetadata.schools)[0].grades[0]
      : undefined
  );

  // watch for changes to selectedSchool and choose the first grade for the selected school
  useEffect(() => {
    if (selectedSchool) {
      setSelectedGrade(selectedSchool.grades[0]);
      // fetch grade level data by school or district (for all grades)
      const fetchGradeLevelData = async () => {
        if (selectedSchool) {
          try {
            let gradeLevelResponse;
            if (selectedSchool.id.includes("_district")) {
              gradeLevelResponse = await fetch("/stories/wkar/by_grade_level");
            } else {
              gradeLevelResponse = await fetch(
                `/stories/wkar/school/${selectedSchool.id}/by_grade_level`
              );
            }

            if (!gradeLevelResponse.ok) {
              throw new Error("Network response was not ok");
            }

            const gradeLevelData = await gradeLevelResponse.json();
            setGradeLevelData(gradeLevelData);
          } catch (error) {
            console.error("Failed to fetch grade level data:", error);
          }
        }
      };

      fetchGradeLevelData();
    }
  }, [selectedSchool]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const metadataResponse = await fetch("/stories/wkar/metadata");
        if (!metadataResponse.ok) {
          throw new Error("Network response was not ok");
        }
        const metadata = await metadataResponse.json();

        const statsResponse = await fetch("/stories/wkar/overall");
        if (!statsResponse.ok) {
          throw new Error("Network response was not ok");
        }
        const stats = await statsResponse.json();

        let updatedSchools = { ...metadata.schools };
        let initialSchool: School | undefined;

        if (metadata.district) {
          const districtOverall: School = {
            id: `${metadata.district.id}_district`,
            name: `${metadata.district.name} Overall`,
            products: metadata.district.products,
            grades: metadata.district.grades,
          };

          updatedSchools = {
            [`${metadata.district.id}_district`]: districtOverall,
            ...metadata.schools,
          };

          initialSchool = districtOverall;
        } else {
          const schoolsArray = Object.values(updatedSchools) as School[];
          initialSchool = schoolsArray[0];
        }

        const updatedMetadata = { ...metadata, schools: updatedSchools };

        setCustomerMetadata(updatedMetadata);
        setOverallStats(stats);
        setSelectedSchool(initialSchool);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  return customerMetadata && selectedSchool && selectedGrade ? (
    <Stories
      customerMetadata={customerMetadata}
      overallStats={overallStats}
      selectedSchool={selectedSchool}
      selectSchool={setSelectedSchool}
      selectedGrade={selectedGrade}
      selectGrade={setSelectedGrade}
      gradeLevelData={gradeLevelData}
    />
  ) : (
    <div>Loading...</div>
  );
};

export default StoriesWrapper;

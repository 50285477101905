import * as React from "react";
import { Toolbar, AppBar, Grid } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { NavbarLogo } from "./NavbarLogo";
import useCheckMobileScreen from "./Utils";
import { RGPHome } from "./RGPHome";
import { NavbarUserSettings } from "./NavbarUserSettings/NavbarUserSettings";
import { WaffleWrapper } from "./Waffle/WaffleWrapper";
import { NavbarHelp } from "./NavbarHelp/NavbarHelp";

export function NavbarGlobal({
  name,
  isMultiCustomerUser,
  isAdmin,
  rgpTenantId,
  tabIndex,
}: Props) {
  const { frontendWaffle } = useFlags();
  let isMobileScreen: boolean = useCheckMobileScreen();
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        color: "black",
      }}
      aria-label="Main Navigation"
    >
      <Toolbar>
        <Grid
          container
          justifyContent={isMobileScreen ? "center" : "space-between"}
          alignItems="center"
        >
          <Grid>
            <NavbarLogo isMobileScreen={isMobileScreen} />
          </Grid>
          <Grid display="flex" justifyContent="flex-end" gap="10px">
            <NavbarHelp rgpTenantId={rgpTenantId} tabIndex={0} />
            <RGPHome />
            {frontendWaffle ? <WaffleWrapper /> : <></>}
            <NavbarUserSettings
              name={name}
              isMultiCustomerUser={isMultiCustomerUser}
              isAdmin={isAdmin}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

interface Props {
  name: string | undefined;
  isMultiCustomerUser: boolean;
  isAdmin: boolean;
  rgpTenantId: string | undefined;
  tabIndex?: number;
}

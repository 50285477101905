import React from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const RALink = styled(Link)(({ theme }) => ({
  color: "var(--blue)",
  "&:link": {
    color: "var(--blue)",
  },
}));

export const LeftNavLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "var(--darkgrey)",
}));

import * as React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Logo } from "../assets/images/renaissance.svg";
import { ReactComponent as RLogo } from "../assets/images/R.svg";

export function NavbarLogo({ isMobileScreen }: { isMobileScreen: boolean }) {
  return (
    <Grid
      item
      sx={{
        width: 120,
        minWidth: 120,
        marginRight: "var(--std-margin-3xl)",
        marginTop: "var(--std-margin-md)",
        alignItem: "center",
      }}
    >
      {isMobileScreen ? (
        <RLogo aria-label="Renaissance Analytics" style={{ width: "40px" }} />
      ) : (
        <Logo aria-label="Renaissance Analytics" />
      )}
    </Grid>
  );
}

import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReportCover from "../../assets/images/report_cover.png";

const Container = styled("div")({
  display: "flex",
  margin: "var(--std-margin-2xl) var(--std-margin-lg)",
});

const Image = styled(Box)({
  width: "53px",
  transform: "rotate(-8.488deg)",
});

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "0 var(--std-margin-xs)",
});

const StyledLink = styled(Link)({
  color: "var(--linkblue)",
  fontSize: "var(--std-font-sm)",
  fontStyle: "italic",
  fontWeight: 600,
  textDecoration: "none", // Ensure the link has no underline
});

const StyledParagraph = styled("p")({
  color: "var(--tablegrey)",
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "var(--std-margin-xs) 0",
});

const LastYearsReportLink = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box
        component="img"
        style={{ width: "43px", transform: "rotate(-8.488deg)" }}
        alt={t("TEXT_WKAR_LAST_YEARS_REPORT_LINK.COVER_ALT")}
        src={ReportCover}
      />
      <TextContainer>
        <StyledLink
          to="https://www.renaissance.com/resources/what-kids-are-reading/"
          target="_blank"
          data-cy="last-years-report-link"
        >
          {t("TEXT_WKAR_LAST_YEARS_REPORT_LINK.DOWNLOAD_REPORT")}
        </StyledLink>
        <StyledParagraph>
          {t("TEXT_WKAR_LAST_YEARS_REPORT_LINK.SHAREABLE_STORY")}
        </StyledParagraph>
      </TextContainer>
    </Container>
  );
};

export default LastYearsReportLink;

import React from "react";
import { styled } from "@mui/system";

const ReadingContainer = styled("div")({
  width: "100%",
  maxWidth: "1031px",
  minHeight: "576px",
  flexShrink: 0,
  borderRadius: "20px",
  background: "var(--white)",
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div")({
  display: "flex",
  flexDirection: "row",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
  },
});

const BeigeBar = styled("div")<{ width: string }>(({ width }) => ({
  width: width,
  borderRadius: "20px 0 0 0",
  "@media (max-width: 600px)": {
    width: "100%",
    borderRadius: "20px 20px 0 0",
  },
  height: "77.589px",
  flexShrink: 0,
  background: "var(--beige)",

  display: "flex",
  justifyContent: "space-between",
}));

const RedBar = styled("div")({
  display: "flex",
  width: "25%",
  borderRadius: "0 20px 0 0",
  "@media (max-width: 600px)": {
    width: "100%",
    borderRadius: "0",
  },
  height: "77.589px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2.4px",
  flexShrink: 0,
  backgroundColor: "var(--red)",
});

const Content = styled("div")({
  display: "flex",
});

interface WKARContainerProps {
  beigeBarWidth: string;
  beigeBarContent: React.ReactNode;
  redBarContent?: React.ReactNode;
  children?: React.ReactNode;
}

const WKARContainer: React.FC<WKARContainerProps> = ({
  beigeBarWidth,
  beigeBarContent,
  redBarContent,
  children,
}) => {
  const isFullWidth = beigeBarWidth === "100%";

  return (
    <ReadingContainer>
      <Header>
        <BeigeBar width={beigeBarWidth} data-cy="beige-bar">
          {beigeBarContent}
        </BeigeBar>
        {!isFullWidth && <RedBar data-cy="red-bar">{redBarContent}</RedBar>}
      </Header>
      <Content data-cy="content">{children}</Content>
    </ReadingContainer>
  );
};

export default WKARContainer;

import React, { useState } from "react";
import { ReactComponent as Freckle } from "../../assets/images/freckle_logo_leftnav.svg";
import { ReactComponent as FreckleActive } from "../../assets/images/freckle_logo_leftnav-active.svg";
import { ReactComponent as Lalilo } from "../../assets/images/lalilo_logo_leftnav.svg";
import { ReactComponent as LaliloActive } from "../../assets/images/lalilo_logo_leftnav-active.svg";
import { ReactComponent as MyON } from "../../assets/images/myon_logo_leftnav.svg";
import { ReactComponent as MyONActive } from "../../assets/images/myon_logo_leftnav-active.svg";
import { ReactComponent as Star } from "../../assets/images/star_logo_leftnav.svg";
import { ReactComponent as StarActive } from "../../assets/images/star_logo_leftnav-active.svg";
import { ReactComponent as Nearpod } from "../../assets/images/nearpod_logo_leftnav.svg";
import { ReactComponent as NearpodActive } from "../../assets/images/nearpod_logo_leftnav-active.svg";
import { ReactComponent as Renu } from "../../assets/images/ren_u_logo_leftnav.svg";
import { ReactComponent as RenuActive } from "../../assets/images/ren_u_logo_leftnav-active.svg";
import { ReactComponent as Reports } from "../../assets/images/reports_logo_leftnav.svg";
import { ReactComponent as ReportsActive } from "../../assets/images/reports_logo_leftnav-active.svg";
import { ReactComponent as AR } from "../../assets/images/ar_logo_leftnav.svg";
import { ReactComponent as ARActive } from "../../assets/images/ar_logo_leftnav-active.svg";
import { ReactComponent as Educlimber } from "../../assets/images/educlimbers_logo_leftnav.svg";
import { ReactComponent as EduclimberActive } from "../../assets/images/educlimbers_logo_leftnav-active.svg";

interface IconProps {
  imagePrefix?: string | undefined;
  isHovered?: boolean | undefined;
  style?: React.CSSProperties;
}

const LeftNavIcon: React.FC<IconProps> = ({
  imagePrefix,
  isHovered,
  style,
}) => {
  const iconStyle = {
    ...style,
    width: "40px",
    height: "40px",
    cursor: "pointer",
    padding: "10px 0px",
  };

  const getIcon = (
    imagePrefix: string | undefined,
    isHovered: boolean | undefined,
    iconStyle?: React.CSSProperties
  ) => {
    switch (imagePrefix) {
      case "freckle_logo":
        return isHovered ? (
          <FreckleActive style={iconStyle} data-testid="freckle-active" />
        ) : (
          <Freckle style={iconStyle} data-testid="freckle-inactive" />
        );
      case "lalilo_logo":
        return isHovered ? (
          <LaliloActive style={iconStyle} />
        ) : (
          <Lalilo style={iconStyle} />
        );
      case "myon_logo":
        return isHovered ? (
          <MyONActive style={iconStyle} />
        ) : (
          <MyON style={iconStyle} />
        );
      case "star_logo":
        return isHovered ? (
          <StarActive style={iconStyle} />
        ) : (
          <Star style={iconStyle} />
        );
      case "schoolzilla_logo":
        return isHovered ? (
          <EduclimberActive style={iconStyle} />
        ) : (
          <Educlimber style={iconStyle} />
        );
      case "educlimber_logo":
        return isHovered ? (
          <EduclimberActive style={iconStyle} />
        ) : (
          <Educlimber style={iconStyle} />
        );
      case "nearpod_logo":
        return isHovered ? (
          <NearpodActive style={iconStyle} />
        ) : (
          <Nearpod style={iconStyle} />
        );
      case "ren_u_logo":
        return isHovered ? (
          <RenuActive style={iconStyle} />
        ) : (
          <Renu style={iconStyle} />
        );
      case "ar_logo":
        return isHovered ? (
          <ARActive style={iconStyle} />
        ) : (
          <AR style={iconStyle} />
        );
      case "reports_logo":
        return isHovered ? (
          <ReportsActive style={iconStyle} />
        ) : (
          <Reports style={iconStyle} />
        );
      default:
        return <></>;
    }
  };

  return (
    <div style={{ pointerEvents: "none" }}>
      {getIcon(imagePrefix, isHovered, iconStyle)}
    </div>
  );
};

export default LeftNavIcon;

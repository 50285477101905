import React, { forwardRef } from "react";
import ModalTemplate from "./ModalTemplate";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow_right_with_tail.svg";
const RenNextScreenshot = require(`../../assets/images/ren_next_screenshot.png`);
const LegacyLoginScreenshot = require(`../../assets/images/legacy_login_screenshot.png`);

interface OptInModalProps {
  handleOptIn: () => void;
  handleClose: (feedback?: string) => void;
}

const OptInModal = forwardRef<HTMLDivElement, OptInModalProps>(
  ({ handleOptIn, handleClose }, ref) => {
    const Wrapper = styled("div")`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 18px;
    `;
    const ImageDescriptor = styled("p")`
      color: var(--tablegrey);
      text-align: center;
      font-size: var(--std-font-sm);
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      margin: 0;
    `;
    const title = "Try Renaissance Next as My New Home Page";
    const description =
      "This will be the page you see after you log in to Renaissance. All Home buttons will return you to this version of the Home page.";
    const buttonDescription = "You can easily switch back at any time.";
    const buttonSecondaryText = "Actually, I'll pass";
    const buttonPrimaryText = "Yes, I'll try it!";
    const middleElement = (
      <Wrapper>
        <div>
          <Box
            component="img"
            sx={{ width: "300px" }}
            alt="Legacy Home Page"
            src={LegacyLoginScreenshot}
          />
          <ImageDescriptor>Current Home</ImageDescriptor>
        </div>
        <ArrowRight style={{ width: "27px" }} />
        <div>
          <Box
            component="img"
            sx={{ width: "300px" }}
            alt="Renaissance Next Home Page"
            src={RenNextScreenshot}
          />
          <ImageDescriptor>Renaissance Next Home</ImageDescriptor>
        </div>
      </Wrapper>
    );

    return (
      <ModalTemplate
        title={title}
        description={description}
        buttonDescription={buttonDescription}
        buttonSecondaryText={buttonSecondaryText}
        buttonPrimaryText={buttonPrimaryText}
        handleSecondaryAction={() => handleClose()}
        handlePrimaryAction={handleOptIn}
        middleElement={middleElement}
      />
    );
  }
);

export default OptInModal;

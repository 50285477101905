import React from "react";
import { styled } from "@mui/system";
import BookContainer from "./BookContainer";
import { Book } from "./StoriesWrapper";
import { ReactComponent as ARLogo } from "../../assets/images/AR_logo-WKAR.svg";

interface ARBooksProps {
  books: Book[];
}

const Container = styled("div")({
  borderRadius: "14px",
  border: "1px solid #E5E5E5", // not a current grey in our library
  padding: "var(--std-padding-xs) var(--std-padding-sm)",
  display: "flex",
  flexDirection: "column",
  margin: "var(--std-margin-lg) var(--std-margin-md) 0 var(--std-margin-md)",
});

const Image = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
  marginBottom: "var(--std-margin-md)",
});

const ARBooks: React.FC<ARBooksProps> = ({ books }) => {
  return (
    <Container>
      <ARLogo />
      <BookContainer books={books} />
    </Container>
  );
};

export default ARBooks;

import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { YourTopBooks } from "./YourTopBooks";
import LastYearsReportLink from "./LastYearsReportLink";
import OverallMetrics from "./OverallMetrics";
import SchoolDropdown from "./SchoolDropdown";
import {
  CustomerMetadata,
  Grade,
  GradeLevelDataResponse,
  OverallStats,
  School,
} from "./StoriesWrapper";
import GradeDropdown from "./GradeDropdown";
import { Explore } from "./Explore";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "0 0 0 var(--std-padding-xlg)",
  width: "100%",
});

const FlexContainer = styled("div")`
  display: flex;
`;

const YourTopBooksContainer = styled("div")({
  margin: "var(--std-margin-lg) 0",
});

const TitleContainer = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const Heading = styled("h1")({
    color: "var(--red)",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    margin: 0,
  });

  const Description = styled("p")({
    color: "var(--tablegrey)",
    fontSize: "var(--std-font-sm)",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "30px",
    margin: 0,
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Heading aria-label={title}>{title}</Heading>
      <Description aria-label={description}>{description}</Description>
    </div>
  );
};

const Stories = ({
  customerMetadata,
  overallStats,
  selectedSchool,
  selectSchool,
  selectedGrade,
  selectGrade,
  gradeLevelData,
}: {
  customerMetadata: CustomerMetadata;
  overallStats: OverallStats | null;
  selectedSchool: School;
  selectSchool: (school: School) => void;
  selectedGrade: Grade;
  selectGrade: (grade: Grade) => void;
  gradeLevelData: GradeLevelDataResponse | null;
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TitleContainer
        title={t("TEXT_STORIES_TITLE")}
        description={t("TEXT_STORIES_DESCRIPTION")}
      />
      <SchoolDropdown
        customerMetadata={customerMetadata}
        selectedSchool={selectedSchool}
        selectSchool={selectSchool}
      />
      {overallStats && (
        <OverallMetrics
          products={customerMetadata?.district?.products}
          school={selectedSchool}
          overallStats={overallStats}
        />
      )}
      <FlexContainer>
        <LastYearsReportLink />
        <GradeDropdown
          selectedSchool={selectedSchool}
          selectedGrade={selectedGrade}
          selectGrade={selectGrade}
        />
      </FlexContainer>
      {gradeLevelData && (
        <YourTopBooksContainer>
          <YourTopBooks
            title={t("TEXT_WYKAR_TITLE")}
            date="JANUARY - DECEMBER 2024"
            selectedGrade={selectedGrade}
            gradeLevelData={gradeLevelData}
          />
        </YourTopBooksContainer>
      )}
      <Explore selectedGrade={selectedGrade} />
    </Container>
  );
};

export default Stories;

export interface Demographics {
  [key: string]: string[] | null;
}
export interface Student {
  lastName: string;
  firstName: string;
  school_id: string;
  studentKEY: string;
  stateStudentID: string;
  systemStudentID: string;
}
export interface Teacher {
  SystemStaffID: string;
  numberOfStudents: number;
  teacherName: string;
}
export interface Section {
  sectionID: string;
  systemStaffID: string;
  courseName: string;
  classPeriod: string;
  teacherLastName: string;
  teacherFirstName: string;
  teacherEmail: string;
  numberOfStudents: number;
}
export interface School {
  schoolLevel: string;
  schoolRegion: string;
  students?: Student[] | null;
  teachers?: Teacher[] | null;
  sections?: Section[] | null;
  demographics: Demographics;
  unsorted_demographics: Demographics;
  officialSchoolName: string;
  schoolYear4Digit: number;
  systemSchoolId: string;
  minGradeLevel: number;
}

export type UserRole =
  | "admin"
  | "school_leader"
  | "district_leader"
  | "teacher"
  | "anonymous";

export interface UserInfoJson {
  hasAllSchools: boolean;
  showDistrictProfile?: boolean;
  showSchoolLeaderAllSchools?: boolean;
  customer_name: string;
  rgp_tenant: string;
  sz_tier: string;
  role: UserRole;
  organizationId: number;
  userId: number | string;
  email: string;
  user_customers?: number[] | null;
  schools?: School[] | null;
  countryCode: "US" | "GB";
  firstName?: string;
  lastName?: string;
}

export function createEmptyUserInfo(): UserInfoJson {
  // TODO: We should be able to get rid of this when we get rid of any of these fields we don't
  // actually use (https://illuminate.atlassian.net/browse/SZSTART-1698)
  return {
    hasAllSchools: false,
    customer_name: "",
    rgp_tenant: "",
    sz_tier: "",
    role: "anonymous",
    organizationId: 0,
    userId: 0,
    email: "",
    user_customers: null,
    schools: null,
    countryCode: "US",
  };
}

export const Entitlements = {
  appnav: [
    {
      applicationId: "4",
      taskId: "400",
    },
    {
      applicationId: "4",
      taskId: "401",
    },
    {
      applicationId: "4",
      taskId: "409",
    },
    {
      applicationId: "4",
      taskId: "402",
    },
    {
      applicationId: "4",
      taskId: "403",
    },
    {
      applicationId: "4",
      taskId: "404",
    },
    {
      applicationId: "4",
      taskId: "405",
    },
    {
      applicationId: "4",
      taskId: "406",
    },
    {
      applicationId: "4",
      taskId: "407",
    },
    {
      applicationId: "7",
      taskId: "700",
    },
    {
      applicationId: "7",
      taskId: "701",
    },
    {
      applicationId: "7",
      taskId: "708",
    },
    {
      applicationId: "7",
      taskId: "702",
    },
    {
      applicationId: "7",
      taskId: "703",
    },
    {
      applicationId: "7",
      taskId: "704",
    },
    {
      applicationId: "7",
      taskId: "705",
    },
    {
      applicationId: "7",
      taskId: "706",
    },
    {
      applicationId: "7",
      taskId: "707",
    },
    {
      applicationId: "5",
      taskId: "500",
    },
    {
      applicationId: "5",
      taskId: "501",
    },
    {
      applicationId: "5",
      taskId: "508",
    },
    {
      applicationId: "5",
      taskId: "502",
    },
    {
      applicationId: "5",
      taskId: "503",
    },
    {
      applicationId: "5",
      taskId: "504",
    },
    {
      applicationId: "5",
      taskId: "505",
    },
    {
      applicationId: "5",
      taskId: "506",
    },
    {
      applicationId: "5",
      taskId: "507",
    },
    {
      applicationId: "26",
      taskId: "2600",
    },
    {
      applicationId: "51",
      taskId: "5100",
    },
    {
      applicationId: "51",
      taskId: "5101",
    },
    {
      applicationId: "51",
      taskId: "5102",
    },
    {
      applicationId: "51",
      taskId: "5103",
    },
    {
      applicationId: "51",
      taskId: "5104",
    },
    {
      applicationId: "51",
      taskId: "5105",
    },
    {
      applicationId: "52",
      taskId: "5200",
    },
    {
      applicationId: "52",
      taskId: "5201",
    },
    {
      applicationId: "52",
      taskId: "5202",
    },
    {
      applicationId: "52",
      taskId: "5203",
    },
    {
      applicationId: "52",
      taskId: "5204",
    },
    {
      applicationId: "52",
      taskId: "5205",
    },
    {
      applicationId: "66",
      taskId: "6600",
    },
    {
      applicationId: "66",
      taskId: "6601",
    },
    {
      applicationId: "66",
      taskId: "6602",
    },
    {
      applicationId: "66",
      taskId: "6603",
    },
    {
      applicationId: "66",
      taskId: "6604",
    },
    {
      applicationId: "66",
      taskId: "6605",
    },
    {
      applicationId: "35",
      taskId: "3502",
    },
    {
      applicationId: "35",
      taskId: "3501",
    },
    {
      applicationId: "35",
      taskId: "3503",
    },
    {
      applicationId: "35",
      taskId: "3504",
    },
    {
      applicationId: "35",
      taskId: "3500",
    },
    {
      applicationId: "35",
      taskId: "3505",
    },
    {
      applicationId: "35",
      taskId: "3506",
    },
    {
      applicationId: "35",
      taskId: "3507",
    },
    {
      applicationId: "68",
      taskId: "6800",
    },
    {
      applicationId: "68",
      taskId: "6801",
    },
    {
      applicationId: "69",
      taskId: "6900",
    },
    {
      applicationId: "36",
      taskId: "3600",
    },
    {
      applicationId: "34",
      taskId: "3400",
    },
    {
      applicationId: "1",
      taskId: "105",
    },
    {
      applicationId: "1",
      taskId: "101",
    },
    {
      applicationId: "1",
      taskId: "888400",
    },
    {
      applicationId: "1",
      taskId: "102",
    },
    {
      applicationId: "1",
      taskId: "106",
    },
    {
      applicationId: "1",
      taskId: "103",
    },
    {
      applicationId: "1",
      taskId: "104",
    },
    {
      applicationId: "49",
      taskId: "4900",
    },
    {
      applicationId: "7779",
      taskId: "777900",
    },
    {
      applicationId: "62",
      taskId: "6200",
    },
    {
      applicationId: "7770",
      taskId: "777000",
    },
    {
      applicationId: "7771",
      taskId: "777100",
    },
    {
      applicationId: "7771",
      taskId: "777101",
    },
    {
      applicationId: "7771",
      taskId: "777103",
    },
    {
      applicationId: "7771",
      taskId: "777104",
    },
    {
      applicationId: "7771",
      taskId: "777105",
    },
    {
      applicationId: "7771",
      taskId: "777106",
    },
    {
      applicationId: "7776",
      taskId: "777600",
    },
    {
      applicationId: "7777",
      taskId: "777700",
    },
    {
      applicationId: "55",
      taskId: "4850",
    },
    {
      applicationId: "56",
      taskId: "4851",
    },
    {
      applicationId: "33",
      taskId: "3301",
    },
    {
      applicationId: "7778",
      taskId: "777800",
    },
    {
      applicationId: "71",
      taskId: "2650",
    },
  ],
};
